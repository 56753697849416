<template>
 <div class="login-container">
   <!-- <el-alert
     v-if="nodeEnv !== 'development'"
     title="beautiful boys and girls欢迎加入vue-admin-beautifulQQ群：972435319"
     type="success"
     :closable="false"
   ></el-alert> -->
   <el-row>
     <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
       <div style="color: transparent;">占位符</div>
     </el-col>
     <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
       <el-form
         ref="form"
         :model="form"
         :rules="rules"
         class="login-form"
         label-position="left"
       >
         <div class="title">
           hello !
         </div>
         <div class="title-tips">欢迎来到{{ title }}！</div>
		<!-- <el-form-item style="margin-top: 40px;" prop="userType">
			 <span class="svg-container svg-container-admin">
			   <vab-icon :icon="['fas', 'user']" />
			 </span>
		  <el-select v-model="form.userType"  placeholder="请选择用户类型">
		      <el-option
		        v-for="item in options"
		        :key="item.value"
		        :label="item.label"
		        :value="item.value">
		      </el-option>
		    </el-select>
		 </el-form-item> -->
         <el-form-item  prop="userLoginId">
           <span class="svg-container svg-container-admin">
             <vab-icon :icon="['fas', 'user']" />
           </span>
           <el-input
             v-model.trim="form.userLoginId"
             v-focus
             placeholder="请输入用户名"
             tabindex="1"
             type="text"
			 @keyup.native="form.userLoginId=form.userLoginId.replace(/[^a-zA-Z0-9]/g,'');"
           />
         </el-form-item>
         <el-form-item prop="password">
           <span class="svg-container">
             <vab-icon :icon="['fas', 'lock']" />
           </span>
           <el-input
             :key="passwordType"
             ref="password"
             v-model.trim="form.password"
             :type="passwordType"
             tabindex="2"
             placeholder="请输入密码"
             @keyup.enter.native="handleLogin"
           />
           <span
             v-if="passwordType === 'password'"
             class="show-password"
             @click="handlePassword"
           >
             <vab-icon :icon="['fas', 'eye-slash']"></vab-icon>
           </span>
           <span v-else class="show-password" @click="handlePassword">
             <vab-icon :icon="['fas', 'eye']"></vab-icon>
           </span>
         </el-form-item>
		 
         <el-button
           :loading="loading"
           class="login-btn"
           type="primary"
           @click="handleLogin"
         >
           登录
         </el-button>
         <!-- <router-link to="/register">
           <div style="margin-top: 20px;">注册</div>
         </router-link> -->
       </el-form>
     </el-col>
   </el-row>
 </div>
</template>


<script>
	export default {
		name: "Login",
		directives: {
		  focus: {
		    inserted(el) {
		      el.querySelector("input").focus();
		    },
		  },
		},
		data(){
			const validateusername = (rule, value, callback) => {
			  if ("" == value) {
			    callback(new Error("用户名不能为空"));
			  } else {
			    callback();
			  }
			};
			const validatePassword = (rule, value, callback) => {
			  if (!this.isPassword(value)) {
			    callback(new Error("密码不能少于6位"));
			  } else {
			    callback();
			  }
			};
			return{
				// options: [{
				//   value: 1,
				//   label: '管理员'
				// }, {
				//   value: 2,
				//   label: '用户'
				//  }],
				 nodeEnv: 'official',
				 title: '全国新媒体快乐学法管理平台',
				 form: {
				   password: "",
				   userLoginId:''
				 },
				  passwordType: "password",
				   loading: false,
				 rules: {
				   userLoginId: [
				     {
				       required: true,
				       trigger: "blur",
				       validator: validateusername,
				     },
				   ],
				   password: [
				     {
				       required: true,
				       trigger: "blur",
				       validator: validatePassword,
				     },
				   ]
				   // ,userType: [{ required: true, message: '请选择用户类型', trigger: 'blur' }]
				 },
			}
		},
		methods:{
			isPassword(str) {
			  return str.length >= 6;
			},
			handlePassword() {
			  this.passwordType === "password"
			    ? (this.passwordType = "")
			    : (this.passwordType = "password");
			  this.$nextTick(() => {
			    this.$refs.password.focus();
			  });
			},
			handleLogin() {
			  var that = this;
			  this.$refs.form.validate(async (valid) => {
			    if (valid) {
			      that.loading = true;
				  that.$comjs.ajax.postAjax('/fa/system/login',that.form,that,(res)=>{
					  this.$message({
						type: 'success',
						message: '登录成功!'
					  });
					  let sessionId = res.sessionId;
					  localStorage.setItem('PIAODU_XF',sessionId);
					  that.loading = false;
					  this.$refs['form'].resetFields();
					  if(this.$route.params.url){
					  	 this.$router.replace({path:this.$route.params.url})
					  }else{
					  	this.$router.replace({path:'/'})
					  }
				  },(error)=>{
					  this.$message({
						type: 'error',
						message: error.message
					  });
					  that.loading = false;
				  })
			    } else {
			      return false;
			    }
			  });
			},
		}
	}
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    min-height: 600px;
    background: url("~@/assets/login_images/background.jpg") center center fixed
      no-repeat;
    background-size: cover;

    .title {
      font-size: 54px;
      font-weight: 500;
      color: rgba(14, 18, 26, 1);
    }

    .title-tips {
      margin-top: 29px;
      font-size: 26px;
      font-weight: 400;
      color: rgba(14, 18, 26, 1);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .login-btn {
      display: inherit;
      width: 220px;
      height: 60px;
      margin-top: 5px;
      border: 0;

      &:hover {
        opacity: 0.9;
      }
    }

    .login-form {
      position: relative;
      max-width: 100%;
      margin: calc((100vh - 425px) / 2) 10% 10%;
      overflow: hidden;

      .forget-password {
        width: 100%;
        margin-top: 40px;
        text-align: left;

        .forget-pass {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(92, 102, 240, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: 14px;
      color:  #fff;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;
      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: #1890ff;
        text-align: center;
      }
    }

    .svg-container {
      position: absolute;
      top: 14px;
      left: 15px;
      z-index: 999;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      top: 14px;
      right: 25px;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        &__content {
          min-height: 32px;
          line-height: 32px;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: 12px;
          line-height: 18px;
          color: #ff4d4f;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          height: 58px;
          padding-left: 45px;
          font-size: 14px;
          line-height: 58px;
          color: #606266;
          background: #f6f4fc;
          border: 0;
          caret-color: #606266;
        }
      }
    }
  }
</style>
